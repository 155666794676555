import React, {useState} from 'react'
import SingleColor from './SingleColor'

import Values from 'values.js'

function App() {
  const [color, setColor] = useState('');
  const [error, setError] = useState(false);
  const [list, setList] = useState(new Values('#1098ad').all(10));

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      let colors = new Values(color).all(10);
      setList(colors)
      setError(false)
    } catch {
      setError(true);
    }
  }

  return <>
    <section className={'container'}>
      <h3>color generator</h3>
      <form onSubmit={handleSubmit}>
        <input type={'text'}
               value={color}
               onChange={(e) => setColor(e.target.value)}
               placeholder={'#1098ad'}
               className={`${error ? 'error' : ''}`}
        />
        <button type={'submit'}
                className={'btn'}>
          generate
        </button>
      </form>
    </section>
    <section className={'colors'}>
      {
        list.map((color, index)=>{
          return <SingleColor key={index} {...color} hex={color.hex} index={index} />
        })
      }
    </section>
  </>
}

export default App

